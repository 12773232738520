export const hhrrRoutes = [
  {
      path:'documentos-gratificacion',
      name:'Documentos-gratificacion',
      component:()=>import('@/views/hhrr/ViewGratificationDocuments.vue'),
      meta:{
          requiresAuth: true,
          breadcrumb:"Documentos de gratificacion",
      }
  },
]