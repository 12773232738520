export const declarationRoutes = [
  {
      path:'declaracion-estados-financieros/:requestId?',
      name:'Detalle-declaracion-estados-financieros',
      component:() => import('@/views/declaration/ViewTaxDeclarationDetail.vue'),
      meta:{
          requiresAuth: true,
          breadcrumb:"Detalle de declaracion de estados financieros",
      }
  },
]