// Others

import {
    createRouter,
    createWebHistory
} from "vue-router"

import {
    adminRoutes
} from "@/router/admin/adminRoutes"
import {
    adsRoutes
} from "./ads/adsRoutes"
import {
    authRoutes
} from "@/router/auth/authRoutes"
import {
    catastroRoutes
} from "./catastro/catastroRoutes"
import {
    constancyRoutes
} from "@/router/constancy/constancyRoutes"
import {
    contributorRoutes
} from "./contributor/contributorRoutes"
import { declarationRoutes } from "./declaration/declarationRoutes"
import {
    employeeRoutes
} from "@/router/employee/employeeRoutes"
import {
    formRoutes
} from "./form/formRoutes"
import {
    hhrrRoutes
} from "./hhrr/hhrrRoutes"
import {
    paymentRoutes
} from "./payment/paymentRoutes"
import {
    portfolioRoutes
} from "@/router/portfolio/portfolioRoutes"
import {
    publicRoutes
} from "@/router/public/publicRoutes"
import {
    refRoutes
} from "@/router/ref/refRoutes"
import {
    rootRoutes
} from "@/router/root/rootRoutes"
import store from "@/store"
import {
    supportRoutes
} from "./support/supportRoutes"
import {
    treasuryRoutes
} from "@/router/treasury/treasuryRoutes"
import {
    tributaryRoutes
} from "@/router/tributary/tributaryRoutes"
import {
    userRoutes
} from "@/router/user/userRoutes"
import {
    verifyRoutes
} from "@/router/verify/verifyRoutes"

const routes = [
    ...authRoutes,
    ...publicRoutes,
    {
        path: "/",
        component: () => import("@/components/AuthLayout.vue"),
        children: [{
                path: "/:role",
                component: () => import("@/views/ViewSystem.vue"),
                children: [
                    ...rootRoutes,
                    ...userRoutes,
                    ...adminRoutes,
                    ...verifyRoutes,
                    ...treasuryRoutes,
                    ...portfolioRoutes,
                    ...refRoutes,
                    ...constancyRoutes,
                    ...employeeRoutes,
                    ...tributaryRoutes,
                    ...formRoutes,
                    ...catastroRoutes,
                    ...supportRoutes,
                    ...adsRoutes,
                    ...paymentRoutes,
                    ...contributorRoutes,
                    ...hhrrRoutes,
                    ...declarationRoutes,
                    {
                        path: "dashboard",
                        name: "Dashboard",
                        component: () => import("@/views/ViewDashboard.vue"),
                        props: true,
                        meta: {
                            requiresAuth: true,
                            title: "Dashboard",
                        }
                    },
                ]
            },
            {
                path: "/cuenta",
                name: "Cuenta",
                component: () => import("@/views/user/Profile/ViewProfile.vue"),
                meta: {
                    requiresAuth: true,
                    breadcrumb: "Cuenta",
                    title: "Cuenta",
                }
            },
            {
                path: "/notificaciones",
                name: "Notificaciones",
                component: () => import('@/views/user/ViewNotifications.vue'),
                meta: {
                    requiresAuth: true,
                    breadcrumb: "Notificaciones",
                    title: "Notificaciones",
                }
            },
            {
                path: "/opciones-usuario",
                name: "Opciones-usuario",
                component: () => import("@/views/ViewSystemOption.vue"),
                meta: {
                    requiresAuth: true,
                    title: "Opciones de usuario",
                }
            },
            {
                path: "/solicitud-verificacion",
                name: "Solicitud-verificacion",
                component: () => import("@/views/user/ViewVerifyAccount.vue"),
                meta: {
                    requiresAuth: true,
                    title: "Solicitud de verificación",
                }
            },
            {
                path: "/nueva-contrasena",
                name: "Nueva-contrasena",
                component: () => import("@/views/auth/ViewCreateNewPassword.vue"),
                meta: {
                    requiresAuth: true,
                    title: "Nueva contraseña",
                }
            },
            {
                path: "/:pathMatch(.*)*",
                name: "Notfound",
                component: () => import("@/views/ViewNotFound.vue")
            }
        ]
    },
]

//Initialization
const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes,
    scrollBehavior() {
        document.getElementById("app").scrollIntoView({
            behavior: "smooth"
        });
    }
})

//Guards
router.beforeEach(async (to, from, next) => {
    window.$progress.start();
    const token = localStorage.getItem('access_token');
    let hasPendingPassword = store.state.auth.user?.hasPendingPassword;

    // Si el usuario aún no está cargado, espera a que se cargue.
    if (token && !store.state.auth.user) {
        console.log('user not loaded, waiting for it to load');
        try {
            await store.dispatch('validateUser'); // Asegúrate de que esta acción cargue el usuario
            hasPendingPassword = store.state.auth.user?.hasPendingPassword;
        } catch (error) {
            console.error('Error al validar usuario:', error);
        }
    }

    // Verificar si la ruta requiere autenticación basándose en la propiedad meta "requiresAuth"
    const requiresAuth = to.matched.some(record => record.meta.requiresAuth);

    // Redirigir al usuario a la página principal ("/") si la ruta requiere autenticación y no hay token
    if (requiresAuth && !token) {
        return next("/");
    }

    // Redirigir a "/opciones-usuario" si el usuario está autenticado y trata de acceder a "/", "/login", "/signup" o "/forgot-password"
    const authRoutes = ["/", "/login", "/signup", "/recuperar-contrasena"];
    if (authRoutes.includes(to.path) && token) {
        return next("/opciones-usuario");
    }

    // Redirigir a "/nueva-contrasena" si hay una sesion activa y el usuario tiene una contraseña pendiente
    if (token && hasPendingPassword != false && to.path !== "/nueva-contrasena") {
        return next("/nueva-contrasena");
    }

    // Establecer el título de la página si está definido en la meta de la ruta
    if (to.meta.title) {
        document.title = to.meta.title;
    }


    // Proceder a la siguiente ruta
    next();
});


router.afterEach(() => {
    window.$progress.finish();
});


export default router