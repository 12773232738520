//Env
const url_base = process.env.VUE_APP_URL_BASE + '/v1'

const v2_url_base = process.env.VUE_APP_URL_BASE + '/v2'

//Prefix
const auth = "cuenta"
const authAccount = "cuenta-usuario"
const checker = "verificador"
const authTax = "impuestos"
const authPay = "pago"
const solvency = "solvencia"
const adminRole = "admin"
const certifications = "certificados"
const constancy = "constancia"
const employee = "empleados"
const root = "root"
const inspection = "inspeccion"
const user = "usuario"
const portfolio = "cartera"
const licence = "licencia"
const form = 'form'
const cadastro = 'cadastro'
const hhrr = 'rrhh'

//Routes admin
export const getRoleManagement = () => `${url_base}/${adminRole}/gestion-rol-usuario`
export const getPermissions = (roleId) => `${url_base}/${adminRole}/listar-permisos/${roleId}`
export const togglePermission = () => `${url_base}/${adminRole}/activar-desactivar-permiso`
export const addPermission = () => `${url_base}/${adminRole}/agregar-permiso`
export const getRoleDetail = (roleId) => `${url_base}/${adminRole}/obtener-detalle-rol/${roleId}`
export const addRole = () => `${url_base}/${adminRole}/agregar-rol`
export const editRole = () => `${url_base}/${adminRole}/editar-rol`
export const getReporteDeuda = ()=> `${url_base}/${adminRole}/obtener-reporte-deudas`
export const getReporteMora = (type,period) => `${url_base}/${adminRole}/obtener-reporte-mora?type=${type}&period=${period}`
export const getSolicitudesVerificacion = () => `${url_base}/${adminRole}/obtener-solicitudes-verificacion-usuarios`
export const verifyUser = ()=> `${url_base}/${adminRole}/actualizar-verificacion-usuario`
export const getSuggestions = ()=> `${url_base}/${adminRole}/obtener-sugerencias`
export const getUsers = ()=> `${url_base}/${adminRole}/obtener-usuarios`
export const getUserSessions = (id)=> `${url_base}/${adminRole}/obtener-sesiones-usuario/${id}`
export const editUserBlockedStatus = ()=> `${url_base}/${adminRole}/bloquear-desbloquear-usuario`
export const editUserEmail = ()=> `${url_base}/${adminRole}/actualizar-email-usuario`
export const editUserPassword = ()=> `${url_base}/${adminRole}/actualizar-clave-usuario`

//Routes root
export const getSystems = () => `${url_base}/${root}/sistemas`
export const getMenus = (systemId) => `${url_base}/${root}/system/${systemId}/menu`
export const getMenuDetail = (menuId) => `${url_base}/${root}/menu/${menuId}`
export const saveMenu = () => `${url_base}/${root}/guardar-menu`
export const updateMenu = () => `${url_base}/${root}/actualizar-menu`
export const deleteMenu = (menuId) => `${url_base}/${root}/eliminar-menu/${menuId}`
export const getDashboardMovements = (type, from, to)=>`${url_base}/${root}/dashboard?type=${type}&fechaInicio=${from}&fechaFinal=${to}`
// export const getServiciosUsuarios = ()=>`${url_base}/${authTax}/buscar-contribuyente`
// export const getServiciosUsuariosDetalle = ()=>`${url_base}/${authTax}/buscar-cuenta`
export const getAccounts = () =>`${url_base}/${authTax}/buscar-contribuyente`
export const getAccountDetail = () =>`${url_base}/${authTax}/buscar-cuenta`
export const getRecibosCementerio = () => `${url_base}/${authTax}/busqueda-recibos-contribuyente`
export const getIncomesPerPeriodsMoa = (from,to) => `${url_base}/${authTax}/obtener-ingresos-moa-periodo?from=${from}&to=${to}`
export const getRequestsUserTaxDeclaration = ()=> `${url_base}/${authTax}/solicitudes-declaracion-impuestos-usuario`
export const getRequestsTaxesDeclarations = ()=> `${url_base}/${authTax}/solicitudes-declaracion-impuestos`
export const getRequestDetailTaxDeclaration = (id)=> `${url_base}/${authTax}/detalle-solicitud-declaracion-impuestos/${id}`
export const getDataAccountDetail = (id) => `${url_base}/${authTax}/informacion-cuenta/${id}`
export const updateTaxDeclarationRequestStatus = () => `${url_base}/${authTax}/actualizar-estado-solicitud-declaracion-impuestos`
export const getTaxDeclarationStatuses = () => `${url_base}/${authTax}/estados-solicitud-declaracion-impuestos`
export const validateFilesTaxDeclaration = () => `${url_base}/${authTax}/validar-archivos-declaracion-impuestos`

export const saveTaxDeclarationBalances = ()=> `${url_base}/${authTax}/registro-declaracion-impuestos-balances`
export const saveTaxDeclarationInventory = ()=> `${url_base}/${authTax}/registro-declaracion-impuestos-inventarios`
export const updateTaxDeclarationBalances = (idRequest)=> `${url_base}/${authTax}/actualizar-declaracion-impuestos-balances/${idRequest}`
export const updateTaxDeclarationInventory = (idRequest)=> `${url_base}/${authTax}/actualizar-declaracion-impuestos-inventario/${idRequest}`
//Routes Auth
export const LogInSesion = () => `${url_base}/${auth}/iniciar-sesion`
export const LogoutSesion = () => `${url_base}/${auth}/cerrar-sesion`
export const refreshSession = () => `${url_base}/${auth}/refrescar-sesion`
export const createAccount = () => `${url_base}/${auth}/crear-cuenta`
export const validateAccount = () => `${url_base}/${auth}/validar-cuenta`
export const registerUser = () => `${url_base}/${auth}/registrar-usuario`
export const validateUser = () => `${url_base}/${auth}/validar-usuario`
export const recoveyPassword = () => `${url_base}/${auth}/recuperar-password`
export const notificationAccount = () => `${url_base}/${auth}/notificaciones`
export const notificationStatus = () => `${url_base}/${auth}/leer-notificacion`
export const changeEmail = () => `${url_base}/${auth}/cambiar-correo`
export const changePassword = () => `${url_base}/${auth}/cambiar-password`
export const registerDataAccount = () => `${url_base}/${auth}/registrar-datos-cuenta`
export const verifyAccountPayment = () => `${url_base}/${auth}/verificar-cuenta-pagada`
export const verifyRequest = ()=> `${url_base}/${auth}/solicitar-verificacion-usuario`
export const getUserRoles = (id) => `${url_base}/${auth}/roles-usuario?userId=${id}`
export const checkPendingVerification = () => `${url_base}/${auth}/verificacion-pendiente` 

//Routes authAccount
export const getMenu = (id) => `${url_base}/${authAccount}/menu/${id}`
export const getRoles = () => `${url_base}/${authAccount}/nombres-roles`
export const getHistoryPayment = () => `${url_base}/${authAccount}/historial-pago`
export const activeUser = () => `${url_base}/${authAccount}/activar-usuario`
export const getSolvencyReason = () => `${url_base}/${authAccount}/motivo-solvencia`
export const getSolvencyPayment = () => `${url_base}/${authAccount}/historial-pagos-solvencia`
export const getDetailsHistoryPayment = (id) => `${url_base}/${authAccount}/detalle-historial-pago/${id}`
export const getAllPayment = (tipo_pago) => `${url_base}/${authAccount}/historial-pagos-cartera/${tipo_pago}`
export const getUserData = (doc, type) => `${url_base}/${authAccount}/detalle-usuario?doc=${doc}&type=${type}`

//Routes Portfolio
export const getAccountManagement = ()=>`${url_base}/${portfolio}/obtener-cuentas-gestion`
export const getDetailAccountManagement = (idCuenta,idTipoCuenta)=> `${url_base}/${portfolio}/obtener-detalle-cuentas-gestion?idCuenta=${idCuenta}&idTipoCuenta=${idTipoCuenta}`
export const getUserAccountManagement = ()=> `${url_base}/${portfolio}/obtener-usuarios-gestion`
export const assingUserToAccount = ()=> `${url_base}/${portfolio}/asignar-cuentas-usuario`
export const getAssignedAccount = ()=> `${url_base}/${portfolio}/obtener-cuenta-gestor`
export const getManagementOptions = ()=> `${url_base}/${portfolio}/obtener-opciones-gestion`
export const managementRegister = ()=> `${url_base}/${portfolio}/guardar-gestion-cuenta`
export const getAccountManagementHistory = (idCuenta,idTipoCuenta)=> `${url_base}/${portfolio}/obtener-gestiones-cuenta?idCuenta=${idCuenta}&idTipoCuenta=${idTipoCuenta}`
export const unassingAccounts = ()=> `${url_base}/${portfolio}/desasignar-cuentas-usuarios`
//Routes checker
export const getAccountRequest = (id) => `${url_base}/${checker}/obtener-coincidencias/${id}`
export const updatePayPlanRequest = () => `${url_base}/${checker}/actualizar-solicitud-plan-pago`

//Routes authTax
export const getPaymentPlan = () => `${url_base}/${authTax}/planes-pago`
export const getTaxesAccount = (userId) => `${url_base}/${authTax}/estado-cuenta${userId ? `?userId=${userId}` : ''}`
export const requestPaymentPlan = () => `${url_base}/${authTax}/solicitar-plan-pago`
export const getPaymentPlanUser = () => `${url_base}/${authTax}/planes-pago-usuario`
export const getTaxesDetails = (id) => `${url_base}/${authTax}/detalle-estado-cuenta/${id}`
export const getTaxesNPE = (codigo) => `${url_base}/${authTax}/estado-cuenta-npe/${codigo}`
export const getPaymentPlanDetail = (idPlan, idTipoCuenta) => `${url_base}/${authTax}/detalle_plan_pago?idPlan=${idPlan}&idTipoCuenta=${idTipoCuenta}`
export const getTaxpayerReceipt = () => `${url_base}/${authTax}/busqueda-recibos-contribuyente`
export const getTaxpayerReceiptDetail = (id) => `${url_base}/${authTax}/detalle-recibo-contribuyente?id=${id}`
export const getAllAccount = (value, type) => `${url_base}/${authTax}/consolidado-cuenta?value=${value}&type=${type}`
export const getIncomeDifferences = (from, until) => `${url_base}/${authTax}/historial-pagos-cartera?from=${from}&until=${until}`
export const getRequestPaymentPlan = () => `${url_base}/${authTax}/solicitud-plan-de-pago-verificador`
export const getEstablishments = () => `${url_base}/${authTax}/empresas-registro-tributario`

//Routes authPay
export const makePayment = () => `${url_base}/${authPay}/generar-pago`
export const getPaymentsLinks = ()=> `${url_base}/${authPay}/obtener-enlaces-pago`
export const getDataToPaymentsLinks = ()=> `${url_base}/${authPay}/datos-enlace-pago`
export const generatePaymentLink = () => `${url_base}/${authPay}/crear-enlace-pago`
export const updateAppliedPayment = (id)=> `${url_base}/${authPay}/aplicar-enlace-pago/${id}`
export const updateDisableLink = (id)=> `${url_base}/${authPay}/actualizar-estado-enlace-pago/${id}`
export const getDataPaymentLink = (id) => `${url_base}/${authPay}/detalle-enlace-pago/${id}`

//Routes Solvency
export const requestSolvency = () => `${url_base}/${solvency}/solicitar-solvencia`
export const detailsSolvency = (rolId) => `${url_base}/${solvency}/solicitudes-solvencia?rolId=${rolId}`
export const getSolvency = (id) => `${url_base}/${solvency}/validar-solvencia/${id}`
export const getSolvencyRequest = () => `${url_base}/${solvency}/solicitud-solvencia-verificador`
export const getAssociatedAccount = (id) => `${url_base}/${solvency}/detalle-solicitud-solvencia/${id}`

export const getSolvencyPlan = () => `${url_base}/${solvency}/solicitudes-planes-pago`
export const updateSolvencyByChecker = () => `${url_base}/${solvency}/actualizar-solicitud-solvencia`

// Routes REF
export const getCertificationsTypes = () => `${url_base}/${certifications}/detalle-certificaciones`
export const getCertificationsRequests = (rolId) => `${url_base}/${certifications}/solicitudes-certificacion?rolId=${rolId}`
export const requestCertification = () => `${url_base}/${certifications}/solicitar-certificacion`
export const getCertificationCheck = () => `${url_base}/${certifications}/solicitud-certificacion-verificador`
export const getPickupPlaces = () => `${url_base}/${certifications}/lugares-entrega`
export const updateRequestCertificate = () => `${url_base}/${certifications}/actualizar-solicitud-certificacion`

// Routes constancy
export const getConstancyRequests = (idRol) => `${url_base}/${constancy}/solicitudes-constancia?rolId=${idRol}`
export const getConstancyDetail = (id) => `${url_base}/${constancy}/detalle-solicitud-constancia/${id}`
export const validateConstancy = (id) => `${url_base}/${constancy}/validar-constancia/${id}`
export const getConstancyReasons = () => `${url_base}/${constancy}/motivo-constancia`
export const requestConstancy = () => `${url_base}/${constancy}/solicitar-constancia`
export const getConstancyChecker = () => `${url_base}/${constancy}/solicitud-constancia-verificador`
export const updateRequestConstancy = () => `${url_base}/${constancy}/actualizar-solicitud-constancia`

// Routes employee
export const getVoucherHistory = (id) => `${url_base}/${employee}/boleta-pago/${id}`
export const getSearchEmployees = (nombre) => `${url_base}/${employee}/buscar-empleado/${nombre}`
export const setVoucherAsViewed = (voucherId) => `${url_base}/${employee}/ver-boleta-pago/${voucherId}`
export const getActiveLoansByDate = (from, until) => `${url_base}/${employee}/prestamos-activados-mes?from=${from}&until=${until}`
export const getEmployeesByDepto = (depto) => `${url_base}/${employee}/departamento/${depto}`
export const getEmployeeDetail = (id) => `${url_base}/${employee}/${id}`


// Reporting routes
export const reportLog = () => `${url_base}/${root}/report-log`
export const getLogs = (day, month, year) => `${url_base}/${root}/get-logs/${day}/${month}/${year}`

//Routes inspection
export const getAccountData = (accountId, accountType) => `${url_base}/${inspection}/datos-cuenta?id=${accountId}&tipoCuenta=${accountType}`
export const getInspectionReason = () => `${url_base}/${inspection}/motivo-inspeccion`
export const postRequestInspection = () => `${url_base}/${inspection}/solicitar-inspeccion`
export const getInspections = () => `${url_base}/${inspection}/solicitudes-inspeccion`

//Routes Public
export const getAccountDataPublic = (accountId) => `${url_base}/${authTax}/detalle-estado-cuenta?id=${accountId}`
export const getRequestDataPublic = (requestId) => `${url_base}/${authTax}/detalle-solicitud?id=${requestId}`
export const makePaymentPublic = () => `${url_base}/${authPay}/pagar`
export const getFormProvider = () => `${url_base}/form/1`
export const saveAnwers = (id) => `${url_base}/${form}/${id}/respuestas`
export const getAnswersById = (id)=> `${url_base}/${form}/lote/${id}/respuestas`
export const getInfoReceipt = (correlativo)=> `${url_base}/${authTax}/busqueda-recibos/${correlativo}`

// User routes
export const sendSuggestion = () => `${url_base}/${user}/enviar-sugerencia`
export const getUserManagements = (id) => `${url_base}/${user}/gestiones/${id}`
export const saveUserManagement = () => `${url_base}/${user}/gestion`
export const getUserInfo = (id) => `${url_base}/${user}/${id}`
export const searchUnlinkedUserAccounts = (id) => `${url_base}/${user}/${id}/cuentas-no-vinculadas`
export const linkUserAccounts = (id) => `${url_base}/${user}/${id}/vincular-cuentas`

// Routes Licence
export const requestLicence = () => `${url_base}/${licence}/solicitar-licencia`
export const getLicenceRequest = () => `${url_base}/${licence}/solicitud-licencia-verificador`
export const updateLicenceByChecker = () => `${url_base}/${licence}/actualizar-solicitud-licencia`
export const getLicence = (id) => `${url_base}/${licence}/validar-licencia/${id}`
export const getLicenseDetail = (id) => `${url_base}/${licence}/${id}`
export const getIssuedLicences = (year) => `${url_base}/${licence}/emitidas?year=${year}`

// Forms routes
export const getFormAnswers = (formId) => `${url_base}/${form}/${formId}/respuestas`
export const getForm = (formId) => `${url_base}/${form}/${formId}`

// Support requests routes
export const getSupportRequests = (roleId, tabKey = null) => `${url_base}/soporte?roleId=${roleId}&tabKey=${tabKey}`
export const createSupportRequest = () => `${url_base}/soporte`
export const getSupportRequestDetail = (id) => `${url_base}/soporte/${id}`
export const updateSupportRequestStatus = (id) => `${url_base}/soporte/${id}/status`
export const updateSupportRequestPriority = (id) => `${url_base}/soporte/${id}/prioridad`
export const assignUserToSupportRequest = (requestId, userId) => `${url_base}/soporte/${requestId}/asignar-usuario/${userId}`
export const unassignUserToSupportRequest = (requestId, userId) => `${url_base}/soporte/${requestId}/desasignar-usuario/${userId}`
export const assignDepartmentToSupportRequest = (requestId) => `${url_base}/soporte/${requestId}/departamento`
export const getSupportTypes = () => `${url_base}/soporte/tipos`
export const getSupportLocations = () => `${url_base}/soporte/ubicaciones`
export const getSupportStatuses = () => `${url_base}/soporte/estados`
export const getSupportStatusOptions = (id, role) => `${url_base}/soporte/${id}/opciones?currentRole=${role}`
export const getSupportHistory = (id) => `${url_base}/soporte/${id}/historial`
export const addCommentToSupportRequest = (id) => `${url_base}/soporte/${id}/comentario`

//common routes
export const getStates = () => `${url_base}/common/municipios`

//Catastro routes
export const getMotivoCatastro = ()=> `${url_base}/${cadastro}/motivo-catastro`
export const saveCatastroRequest = ()=> `${url_base}/${cadastro}/save-constancy`
export const catastroUserRequest = (rol) => `${url_base}/${cadastro}/user-constancy-cadastro?rolId=${rol}`
export const getConstanciesCatastro = ()=> `${url_base}/${cadastro}/users-constancies-cadastro`
export const updateCatastroConstancy = ()=> `${url_base}/${cadastro}/save-constancy`
export const getConstancyCatastroPaid = (id) => `${url_base}/${cadastro}/get-constany-cadastro-paid/${id}`
export const getDetailCatastro = (id)=> `${url_base}/${cadastro}/detail-cadastro-constancies/${id}`
export const validationCatastro = (id) => `${url_base}/${cadastro}/validation-constancy-cadatro/${id}`

// Ads routes
export const getAds = () => `${url_base}/anuncios`
export const saveAd = () => `${url_base}/anuncios`
export const getAdDetail = (id) => `${url_base}/anuncios/${id}`
export const getAvailableAds = () => `${url_base}/anuncios/disponibles`
export const updateAd = (id) => `${url_base}/anuncios/${id}`
export const setAdAsViewed = (id) => `${url_base}/anuncios/${id}/visto`

// Hhrr routes
export const calculateGratification = () => `${url_base}/${hhrr}/gratificacion`
export const getGratifications = () => `${url_base}/${hhrr}/gratificaciones`

export const getAccountsV2 = (document) => `${v2_url_base}/cuentas?document=${document}`
export const getAccountCharges = (accountId, accountTypeId) => `${v2_url_base}/cuentas/${accountId}/cargos?idTipoCuenta=${accountTypeId}`

export const getContributorUser = (document) => `${v2_url_base}/contribuyente/${document}/usuario`
